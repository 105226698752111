import {
  FETCH_SEAT_LAYOUT_LOADING,
  FETCH_SEAT_LAYOUT_SUCCESS,
  FETCH_SEAT_LAYOUT_ERROR,
  FETCH_SHOW_DETAILS_SUCCESS,
  SET_SEAT_SELECTED,
  SET_OFFER_SEAT_SELECTED,
  SET_RESERVATION_ID,
  SET_SEAT_SELECT_ERROR,
  SET_TRANSACTION_ID,
  SET_TRANSACTION_ERROR,
  CLEAR_BOOKING_STATE,
  SET_TICKET_PRICE,
  RESET_RESERVATION_ID,
  SET_GUEST_ID,
  START_TIMER,
  STOP_TIMER,
  SET_BOOKING_FEE_DATA,
  SET_ADDITIONAL_DATA,
  CLEAR_ERRORS,
  CLEAR_SELECTED_SEATS,
  CLEAR_TIMEOUT_POPUP,
  SET_VOUCHER_CODE_SUCCESS,
  SET_VOUCHER_CODE_ERROR,
  SET_SESSION_DETAILS,
  SET_BANK_OFFERS_LIST,
  SET_SELECTED_BANK_OFFER,
  REMOVE_SELECTED_BANK_OFFER,
  SET_REMAINING_SEATS_LIMIT,
  CLEAR_CINEPOLIS_BANK_VOUCHER_OFFERS,
  SET_OFFERTYPE
} from "./bookingTypes";

import { BANK_OFFER, VOUCHER } from "./bookingTypes";

import cloneDeep from "lodash/cloneDeep";
import round from "lodash/round";

const initialState = {
  seat_layout_loading: false,
  original_seat_layout: [],
  seat_layout: [],
  isVoucherAvailable: false,
  offersDataUpdated: null,
  cinepolisOffers: null,
  bogoOfferSeatNotMatch: false,
  seat_layout_error: null,
  show_details: [],
  seats_selected: [],
  vista_seats_selected: [],
  reservation_id: null,
  guest_id: null,
  seat_select_error: null,
  transaction_id: null,
  transaction_error: null,
  ticketPrice: null,
  selectedTicketPrice: {
    priceInCents: 0,
    taxInCents: 0,
    totalWithFnb: 0,
    originalPriceInCents: 0,
    originalTaxInCents: 0,
    originalTotalWithFnb: 0,
  },
  bookingFee: 0,
  bookingFeeTax: 0,
  timer: {
    minutes: 8,
    seconds: 59,
  },
  showTimeoutPopup: false,
  additionalData: {},
  voucherCodeData: null,
  voucherCodeError: null,
  bankOffersList: [],
  selectedBankOfferTicket: null,
  selectedBankOfferCardObj: null,
  bankOfferUserCardDetails: {
    firstSixDigits: null,
    lastSixDigits: null,
  },
  remaining_seats: null,
  session_id: null,
  cinema_id: null,
  taxRate: 0,
  offerType: "",
};

const bookingReducer = (state = initialState, action) => {
  console.log(state, "stateeget")
  switch (action.type) {
    case RESET_RESERVATION_ID:
      return {
        ...state,
        reservation_id: null,
      };
    case FETCH_SEAT_LAYOUT_LOADING:
      return {
        ...state,
        seat_layout_loading: true,
      };
    case CLEAR_CINEPOLIS_BANK_VOUCHER_OFFERS:
      return {
        ...state,
        cinepolisOffers: null,
        selectedBankOfferCardObj: null,
        voucherCodeData: null,
        offerType: ""
      };

    case FETCH_SEAT_LAYOUT_SUCCESS:
      return {
        ...state,
        seat_layout_loading: false,
        seat_layout: action.payload,
        original_seat_layout: cloneDeep(action.payload),
        isVoucherAvailable: action.isVoucherAvailable,
        offersDataUpdated: action.offersDataUpdated,
        seat_layout_error: null,
        selectedTicketPrice: {
          priceInCents: 0,
          taxInCents: 0,
          totalWithFnb: 0,
          originalPriceInCents: 0,
          originalTaxInCents: 0,
          originalTotalWithFnb: 0,
        },
      };
    case FETCH_SEAT_LAYOUT_ERROR:
      return {
        ...state,
        seat_layout_loading: false,
        seat_layout: [],
        isVoucherAvailable: false,
        cinepolisOffers: null,
        bogoOfferSeatNotMatch: false,
        original_seat_layout: [],
        seat_layout_error: action.payload,
        selectedTicketPrice: {
          priceInCents: 0,
          taxInCents: 0,
          totalWithFnb: 0,
          originalPriceInCents: 0,
          originalTaxInCents: 0,
          originalTotalWithFnb: 0,
        },
      };
    case FETCH_SHOW_DETAILS_SUCCESS:
      return {
        ...state,
        show_details: action.payload,
        taxRate:
          action.payload[0] &&
            (action.payload[0].tax_percent !== null ||
              action.payload[0].tax_percent !== undefined)
            ? action.payload[0].tax_percent / 100
            : 0,
      };
    case SET_SEAT_SELECTED:
      console.log(state.original_seat_layout, "seat_layout");
      let seatTypeIndex = action.payload.seatTypeIndex;
      let rowIndex = action.payload.rowIndex;
      let seatIndex = action.payload.seatIndex;
      let Position = action.payload.Position;
      let adjacentSeatPosition = action.payload.adjacentSeatPosition;

      // console.log(action.payload.Position, "action.payload.Position >>>>>>>>>>>>>>>>")

      let isCoupleSeat =
        state.seat_layout[seatTypeIndex].Rows[rowIndex].Seats[seatIndex]
          .coupleSeat;

      Position.TicketTypeCode = state.seat_layout[seatTypeIndex].TicketTypeCode;
      Position.isVoucher = false;
      Position.isBogo = false;
      Position.priceInCents = 0;
      Position.isFamilyBundle = false;



      Position.coupleSeat = isCoupleSeat;
      Position.isBankOffer = false;

      if (adjacentSeatPosition) {
        adjacentSeatPosition.TicketTypeCode =
          state.seat_layout[seatTypeIndex].TicketTypeCode;
        adjacentSeatPosition.isVoucher = false;
        adjacentSeatPosition.isBankOffer = false;
        adjacentSeatPosition.isBogo = false;
        adjacentSeatPosition.priceInCents = 0;
        adjacentSeatPosition.coupleSeat = isCoupleSeat;
      }

      let singleSeat = action.payload.singleSeat;
      // selectedTicketPrice: {
      //   priceInCents: 0,
      //   taxInCents: 0,
      //   totalWithFnb: 0
      // }
      const newSeatLayout = [...state.seat_layout];
      let selectedTicketPrice = Object.assign({}, state.selectedTicketPrice);

      let ticketTypeCodeIndex = null;

      if (state.voucherCodeData) {
        ticketTypeCodeIndex = state.voucherCodeData.ticketTypes.findIndex(
          (ticketType) => {
            return newSeatLayout[seatTypeIndex].TicketTypeCodeBarcode.includes(
              ticketType.ticketTypeCode
            );
          }
        );
      }

      const priceWithTax = newSeatLayout[seatTypeIndex].PriceInCents;
      const priceWithoutTax = parseFloat(
        (priceWithTax / (1 + state.taxRate)).toFixed(2)
      );
      const tax = parseFloat((priceWithTax - priceWithoutTax).toFixed(2));

      let voucherPriceWithTax;
      let voucherPriceWithoutTax;
      let voucherTax;

      if (ticketTypeCodeIndex !== null && ticketTypeCodeIndex !== -1) {
        const targetTicketTypeCode =
          state.voucherCodeData.ticketTypes[ticketTypeCodeIndex].ticketTypeCode;
        voucherPriceWithTax =
          newSeatLayout[seatTypeIndex].voucherCodePrices[targetTicketTypeCode]
            .PriceInCents;
        voucherPriceWithoutTax = parseFloat(
          (voucherPriceWithTax / (1 + state.taxRate)).toFixed(2)
        );
        voucherTax = parseFloat(
          (voucherPriceWithTax - voucherPriceWithoutTax).toFixed(2)
        );
        console.log(voucherTax);
      }

      let bankOfferPriceWithTax;
      let bankOfferPriceWithoutTax;
      let bankOfferTax;

      if (
        state.offerType === BANK_OFFER &&
        state.selectedBankOfferCardObj &&
        state.selectedBankOfferTicket
      ) {
        const targetTicketTypeCode =
          state.selectedBankOfferTicket.TicketTypeCode;
        bankOfferPriceWithTax =
          newSeatLayout[seatTypeIndex].bankOffersPrices[targetTicketTypeCode]
            ?.PriceInCents;
        bankOfferPriceWithoutTax = parseFloat(
          (bankOfferPriceWithTax / (1 + state.taxRate)).toFixed(2)
        );
        bankOfferTax = parseFloat(
          (bankOfferPriceWithTax - bankOfferPriceWithoutTax).toFixed(2)
        );
      }

      if (
        newSeatLayout[seatTypeIndex].Rows[rowIndex].Seats[
          seatIndex
        ].hasOwnProperty("SeatSelected") &&
        newSeatLayout[seatTypeIndex].Rows[rowIndex].Seats[seatIndex]
          .SeatSelected
      ) {
        // removing seats
        newSeatLayout[seatTypeIndex].Rows[rowIndex].Seats[seatIndex][
          "SeatSelected"
        ] = false;




        if (
          state.voucherCodeData &&
          ticketTypeCodeIndex !== null &&
          ticketTypeCodeIndex !== -1 &&
          ((state.voucherCodeData.isBogo &&
            state.seats_selected.length <=
            state.voucherCodeData.numberOfRedemptionsRemaining * 2) ||
            (!state.voucherCodeData.isBogo &&
              state.seats_selected.length <=
              state.voucherCodeData.numberOfRedemptionsRemaining))
        ) {

          if (
            !state.voucherCodeData.isBogo ||
            (!adjacentSeatPosition &&
              state.voucherCodeData.isBogo &&
              state.seats_selected.length % 2 === 1) ||
            (adjacentSeatPosition &&
              state.voucherCodeData.isBogo &&
              state.seats_selected.length % 4 === 2)
          ) {
            if (selectedTicketPrice.priceInCents !== 0) {
              selectedTicketPrice.priceInCents -= voucherPriceWithTax;
            }

            if (selectedTicketPrice.totalWithFnb !== 0) {
              selectedTicketPrice.totalWithFnb -= voucherPriceWithTax;
            }

            if (selectedTicketPrice.taxInCents !== 0) {
              selectedTicketPrice.taxInCents -= voucherTax;
            }
          }
        } else if (
          state.offerType === BANK_OFFER &&
          state.selectedBankOfferCardObj &&
          state.selectedBankOfferTicket
        ) {



          if (
            (!adjacentSeatPosition &&
              state.selectedBankOfferTicket.isBogo &&
              state.seats_selected.length % 2 === 1) ||
            (adjacentSeatPosition &&
              state.selectedBankOfferTicket.isBogo &&
              state.seats_selected.length % 4 === 2) ||
            !state.selectedBankOfferTicket.isBogo
          ) {
            if (selectedTicketPrice.priceInCents !== 0) {
              selectedTicketPrice.priceInCents -= bankOfferPriceWithTax;
            }

            if (selectedTicketPrice.totalWithFnb !== 0) {
              selectedTicketPrice.totalWithFnb -= bankOfferPriceWithTax;
            }

            if (selectedTicketPrice.taxInCents !== 0) {
              selectedTicketPrice.taxInCents -= bankOfferTax;
            }
          }
        } else {



          if (selectedTicketPrice.priceInCents !== 0) {
            selectedTicketPrice.priceInCents -= priceWithTax;
          }

          if (selectedTicketPrice.totalWithFnb !== 0) {
            selectedTicketPrice.totalWithFnb -= priceWithTax;
          }

          if (selectedTicketPrice.taxInCents !== 0) {
            selectedTicketPrice.taxInCents -= tax;
          }
        }

        if (selectedTicketPrice.originalPriceInCents !== 0) {
          selectedTicketPrice.originalPriceInCents -= priceWithTax;
        }

        if (selectedTicketPrice.originalTotalWithFnb !== 0) {
          selectedTicketPrice.originalTotalWithFnb -= priceWithTax;
        }

        if (selectedTicketPrice.originalTaxInCents !== 0) {
          selectedTicketPrice.originalTaxInCents = round(
            selectedTicketPrice.originalTaxInCents - tax,
            2
          );
        }
      } else {
        // adding seats



        // console.log(state?.cinepolisOffers , "state >>>>>>>>>>>>>>>> >>>>>>>>>>> >>>")

        if (
          state?.cinepolisOffers &&
          state?.cinepolisOffers.length > 0 &&
          state?.cinepolisOffers[0].Description.includes("5") && !state?.cinepolisOffers[0].Description.includes("50") && !state?.cinepolisOffers[0].Description.includes("51") &&
          state?.cinepolisOffers[0].Description.includes("PROMO")
        ) {
          if (
            state.seats_selected.length === 1 ||
            (!adjacentSeatPosition &&
              state.remaining_seats &&
              state.seats_selected.length === state.remaining_seats) ||
            (adjacentSeatPosition &&
              state.remaining_seats &&
              state.seats_selected.length + 2 > state.remaining_seats)
          ) {
            return state;
          }
        } else {
          if (
            state.seats_selected.length === 6 ||
            (!adjacentSeatPosition &&
              state.remaining_seats &&
              state.seats_selected.length === state.remaining_seats) ||
            (adjacentSeatPosition &&
              state.remaining_seats &&
              state.seats_selected.length + 2 > state.remaining_seats)
          ) {
            return state;
          }
        }



        // if(state.voucherCodeData) {
        //   if((state.voucherCodeData.isBogo && state.seats_selected.length === state.voucherCodeData.numberOfRedemptionsRemaining * 2) || (!state.voucherCodeData.isBogo && state.seats_selected.length === state.voucherCodeData.numberOfRedemptionsRemaining)) {
        //     return state;
        //   }
        // }

        if (ticketTypeCodeIndex !== null && ticketTypeCodeIndex !== -1) {

          if (state.voucherCodeData.isBogo) {
            if (
              state.seats_selected.length <
              state.voucherCodeData.numberOfRedemptionsRemaining * 2
            ) {
              Position.isVoucher = true;
              Position.isBogo = true;
            }
          } else if (state.voucherCodeData.isOnlyThree) {
            // Position.isVoucher = true;
            Position.isOnlyThree = true;
          }
          else if (state.voucherCodeData.isOnlyFour) {
            // Position.isVoucher = true;
            Position.isOnlyFour = true;
          }
          else if (state.voucherCodeData.isOnlyFive) {
            // Position.isVoucher = true;
            Position.isOnlyFive = true;
          }
          else if (state.voucherCodeData.isOnlySix) {
            // Position.isVoucher = true;
            Position.isOnlySix = true;
          }

          else {

            if (
              state.seats_selected.length <
              state.voucherCodeData.numberOfRedemptionsRemaining
            ) {
              console.log("voucher ticket >>>>>>>>>>>")
              Position.isVoucher = true;
            }
          }
        } else if (
          state.offerType === BANK_OFFER &&
          state.selectedBankOfferCardObj &&
          state.selectedBankOfferTicket
        ) {
          Position.isBankOffer = true;
          Position.TicketTypeCode =
            state.selectedBankOfferTicket.TicketTypeCode;
          if (adjacentSeatPosition) {
            adjacentSeatPosition.isBankOffer = true;
            adjacentSeatPosition.TicketTypeCode =
              state.selectedBankOfferTicket.TicketTypeCode;
          }

          if (state.selectedBankOfferTicket.isBogo) {
            Position.isBogo = true;
          }
        }

        newSeatLayout[seatTypeIndex].Rows[rowIndex].Seats[seatIndex][
          "SeatSelected"
        ] = true;

        if (
          // state?.voucherCodeData?.Description != "DELUXE - ENTERTAINER BH" &&
          ticketTypeCodeIndex !== null &&
          ticketTypeCodeIndex !== -1 &&
          ((state.voucherCodeData.isBogo &&
            state.seats_selected.length <
            state.voucherCodeData.numberOfRedemptionsRemaining * 2) ||
            (!state.voucherCodeData.isBogo &&
              state.seats_selected.length <
              state.voucherCodeData.numberOfRedemptionsRemaining) ||
            (state.voucherCodeData.isOnlyThree &&
              state.seats_selected.length <
              state.voucherCodeData.numberOfRedemptionsRemaining * 3) ||
            (state.voucherCodeData.isOnlyFour &&
              state.seats_selected.length <
              state.voucherCodeData.numberOfRedemptionsRemaining * 4) ||
            (state.voucherCodeData.isOnlyFive &&
              state.seats_selected.length <
              state.voucherCodeData.numberOfRedemptionsRemaining * 5) ||
            (state.voucherCodeData.isOnlySix &&
              state.seats_selected.length <
              state.voucherCodeData.numberOfRedemptionsRemaining * 6)

          )
        ) {

          // console.log(state.voucherCodeData, "harshal >>>>>>>>>>>>>>>>>>> >>>>>>>")
          if (
            (!state.voucherCodeData.isOnlyThree) &&
            (!state.voucherCodeData.isOnlyFour) &&
            (!state.voucherCodeData.isOnlyFive) &&
            (!state.voucherCodeData.isOnlySix)
            &&
            (!state.voucherCodeData.isBogo ||
              (!adjacentSeatPosition &&
                state.voucherCodeData.isBogo &&
                state.seats_selected.length % 2 === 0) ||
              (adjacentSeatPosition &&
                state.voucherCodeData.isBogo &&
                state.seats_selected.length % 4 === 0))
          ) {
            console.log("coming here. voucher price added");
            selectedTicketPrice.totalWithFnb += voucherPriceWithTax;
            selectedTicketPrice.priceInCents += voucherPriceWithTax;
            selectedTicketPrice.taxInCents += voucherTax;
            Position.priceInCents = voucherPriceWithTax;

            // selectedTicketPrice.taxInCents += newSeatLayout[seatTypeIndex].voucherCodePrices[targetTicketTypeCode].TaxInCents
          }

          else if (
            state.voucherCodeData.isOnlyThree &&
            (!state.voucherCodeData.isOnlyThree ||
              (!adjacentSeatPosition &&
                state.voucherCodeData.isOnlyThree &&
                state.seats_selected.length % 3 === 0) ||
              (adjacentSeatPosition &&
                state.voucherCodeData.isOnlyThree &&
                state.seats_selected.length % 6 === 0))
          ) {
            console.log("coming here. voucher price added 1");
            selectedTicketPrice.totalWithFnb += voucherPriceWithTax;
            selectedTicketPrice.priceInCents += voucherPriceWithTax;
            selectedTicketPrice.taxInCents += voucherTax;
            Position.priceInCents = voucherPriceWithTax;

            // selectedTicketPrice.taxInCents += newSeatLayout[seatTypeIndex].voucherCodePrices[targetTicketTypeCode].TaxInCents
          }
          else if (
            state.voucherCodeData.isOnlyFour &&
            (!state.voucherCodeData.isOnlyFour ||
              (!adjacentSeatPosition &&
                state.voucherCodeData.isOnlyFour &&
                state.seats_selected.length % 4 === 0) ||
              (adjacentSeatPosition &&
                state.voucherCodeData.isOnlyFour &&
                state.seats_selected.length % 4 === 0))
          ) {
            console.log("coming here. voucher price added 1");
            selectedTicketPrice.totalWithFnb += voucherPriceWithTax;
            selectedTicketPrice.priceInCents += voucherPriceWithTax;
            selectedTicketPrice.taxInCents += voucherTax;
            Position.priceInCents = voucherPriceWithTax;

            // selectedTicketPrice.taxInCents += newSeatLayout[seatTypeIndex].voucherCodePrices[targetTicketTypeCode].TaxInCents
          }

          else if (
            state.voucherCodeData.isOnlyFive &&
            (!state.voucherCodeData.isOnlyFive ||
              (!adjacentSeatPosition &&
                state.voucherCodeData.isOnlyFive &&
                state.seats_selected.length % 5 === 0) ||
              (adjacentSeatPosition &&
                state.voucherCodeData.isOnlyFive &&
                state.seats_selected.length % 5 === 0))
          ) {
            console.log("coming here. voucher price added 1");
            selectedTicketPrice.totalWithFnb += voucherPriceWithTax;
            selectedTicketPrice.priceInCents += voucherPriceWithTax;
            selectedTicketPrice.taxInCents += voucherTax;
            Position.priceInCents = voucherPriceWithTax;

            // selectedTicketPrice.taxInCents += newSeatLayout[seatTypeIndex].voucherCodePrices[targetTicketTypeCode].TaxInCents
          }
          else if (
            state.voucherCodeData.isOnlySix &&
            (!state.voucherCodeData.isOnlySix ||
              (!adjacentSeatPosition &&
                state.voucherCodeData.isOnlySix &&
                state.seats_selected.length % 6 === 0) ||
              (adjacentSeatPosition &&
                state.voucherCodeData.isOnlySix &&
                state.seats_selected.length % 6 === 0))
          ) {
            console.log("coming here. voucher price added 1");
            selectedTicketPrice.totalWithFnb += voucherPriceWithTax;
            selectedTicketPrice.priceInCents += voucherPriceWithTax;
            selectedTicketPrice.taxInCents += voucherTax;
            Position.priceInCents = voucherPriceWithTax;

            // selectedTicketPrice.taxInCents += newSeatLayout[seatTypeIndex].voucherCodePrices[targetTicketTypeCode].TaxInCents
          }
          else if (

            state.voucherCodeData.isBogo &&
            (!state.voucherCodeData.isBogo ||
              (!adjacentSeatPosition &&
                state.voucherCodeData.isBogo &&
                state.seats_selected.length % 2 === 0) ||
              (adjacentSeatPosition &&
                state.voucherCodeData.isBogo &&
                state.seats_selected.length % 4 === 0))
          ) {
            console.log("coming here. voucher price added 2");
            selectedTicketPrice.totalWithFnb += voucherPriceWithTax;
            selectedTicketPrice.priceInCents += voucherPriceWithTax;
            selectedTicketPrice.taxInCents += voucherTax;
            Position.priceInCents = voucherPriceWithTax;

            // selectedTicketPrice.taxInCents += newSeatLayout[seatTypeIndex].voucherCodePrices[targetTicketTypeCode].TaxInCents
          }
        }
        // else if  (
        //   (Position?.isVoucher == true) && (state?.voucherCodeData?.Description == "DELUXE - ENTERTAINER BH")
        // ) {

        //   console.log(state.voucherCodeData, "harshal 3 >>>>>>>>>>>>>>>>>>>")

        //   if (
        //     (Position?.isVoucher == true) && (state?.voucherCodeData?.Description == "DELUXE - ENTERTAINER BH")

        //     &&
        //   (  !state.voucherCodeData.isBogo ||
        //     (!adjacentSeatPosition && 
        //       state.voucherCodeData.isBogo &&
        //       state.seats_selected.length % 2 === 0) ||
        //     (adjacentSeatPosition &&
        //       state.voucherCodeData.isBogo &&
        //       state.seats_selected.length % 4 === 0))
        //   ) {
        //     console.log("coming here. voucher price added");
        //     selectedTicketPrice.totalWithFnb += voucherPriceWithTax;
        //     selectedTicketPrice.priceInCents += voucherPriceWithTax;
        //     selectedTicketPrice.taxInCents += voucherTax;
        //     Position.priceInCents = voucherPriceWithTax;

        //     // selectedTicketPrice.taxInCents += newSeatLayout[seatTypeIndex].voucherCodePrices[targetTicketTypeCode].TaxInCents
        //   }
        // } 


        else if (
          state.offerType === BANK_OFFER &&
          state.selectedBankOfferCardObj &&
          state.selectedBankOfferTicket
        ) {
          if (
            (!adjacentSeatPosition &&
              state.selectedBankOfferTicket.isBogo &&
              state.seats_selected.length % 2 === 0) ||
            (adjacentSeatPosition &&
              state.selectedBankOfferTicket.isBogo &&
              state.seats_selected.length % 4 === 0) ||
            !state.selectedBankOfferTicket.isBogo
          ) {
            console.log("coming here. bank offer price added");
            selectedTicketPrice.totalWithFnb += bankOfferPriceWithTax;
            selectedTicketPrice.priceInCents += bankOfferPriceWithTax;
            selectedTicketPrice.taxInCents += bankOfferTax;
            Position.priceInCents = bankOfferPriceWithTax;
          }
        } else {
          console.log("coming here. normal price added");
          // if (priceWithTax) {
          selectedTicketPrice.totalWithFnb += priceWithTax;

          selectedTicketPrice.priceInCents += priceWithTax;
          Position.priceInCents = priceWithTax;
          // }
          // selectedTicketPrice.taxInCents += newSeatLayout[seatTypeIndex].TaxInCents
          selectedTicketPrice.taxInCents += tax;
        }

        selectedTicketPrice.originalTotalWithFnb += priceWithTax;
        selectedTicketPrice.originalPriceInCents += priceWithTax;
        selectedTicketPrice.originalTaxInCents = round(
          selectedTicketPrice.originalTaxInCents + tax,
          2
        );

        selectedTicketPrice.TicketTypeCode =
          newSeatLayout[seatTypeIndex].TicketTypeCode;

        if (
          state.offerType === BANK_OFFER &&
          state.selectedBankOfferCardObj &&
          state.selectedBankOfferTicket
        ) {
          selectedTicketPrice.TicketTypeCode =
            state.selectedBankOfferTicket.TicketTypeCode;
        }

        // newSeatLayout[seatTypeIndex].PriceInCents
        // newSeatLayout[seatTypeIndex].TaxInCents
      }
      let seatName2 = "";
      let seatName =
        state.seat_layout[seatTypeIndex].Rows[rowIndex].PhysicalName +
        "-" +
        state.seat_layout[seatTypeIndex].Rows[rowIndex].Seats[seatIndex].Id;
      if (singleSeat.coupleSeat) {
        seatName2 =
          state.seat_layout[seatTypeIndex].Rows[rowIndex].PhysicalName +
          "-" +
          state.seat_layout[seatTypeIndex].Rows[rowIndex].Seats[seatIndex + 1]
            .Id;
      }
      const newSeats = [...state.seats_selected];

      const newVistaSeats = [...state.vista_seats_selected];
      if (newSeats.indexOf(seatName) > -1) {
        newSeats.splice(newSeats.indexOf(seatName), 1);
        newVistaSeats.splice(newVistaSeats.indexOf(Position), 1);
        if (singleSeat.coupleSeat) {
          newSeats.splice(newSeats.indexOf(seatName2), 1);
          newVistaSeats.splice(newVistaSeats.indexOf(adjacentSeatPosition), 1);
        }
      } else {
        newSeats.push(seatName);
        newVistaSeats.push(Position);
        if (singleSeat.coupleSeat) {
          newSeats.push(seatName2);
          newVistaSeats.push(adjacentSeatPosition);
        }
      }

      console.log(state, "state >>>>>>>")

      console.log(newVistaSeats, "state.vista_seats_selected");
      return {
        ...state,
        selectedTicketPrice: selectedTicketPrice,
        seat_layout: newSeatLayout,
        seats_selected: newSeats,
        vista_seats_selected: newVistaSeats,
        seat_select_error: null,
      };

    case SET_OFFER_SEAT_SELECTED:
      if (state.selectedBankOfferTicket || state.voucherCodeData) {
        return false;
      }

      let userSelectedOffer = action.payload.SelectedCategoryOfferForCheck;
      console.log(userSelectedOffer, "userSelectedOffer >>>>>>>>>>>>>>>")
      let bogofree = 0;
      let familyBundlefree = 0
      let bogoprice = 0;

      console.log(
        state.selectedTicketPrice.priceInCents,
        "state.selectedTicketPrice.priceInCents"
      );
      userSelectedOffer.map((item, index) => {



        familyBundlefree = 0
        bogofree = 0;
        state.vista_seats_selected.map((item1, index1) => {


          // console.log(item1, "item1.AreaCategoryCode >>>>>>>>>>")
          // console.log(item, "item.AreaCategoryCode >>>>>>>>>>")


          if (
            item1.AreaCategoryCode == item.AreaCategoryCode
            // &&
            // item.PackageContent &&
            // item.PackageContent.Tickets &&
            // item.PackageContent.Tickets.length > 1
          ) {

            // console.log("first inside >>>>>>>>>>>>>>>>>>>>>>>>>>>")

            if (index1 % 2 === 0) {
              // console.log("Is Bogo true one")
              item1.TicketTypeCode = item.TicketTypeCode;
              state.vista_seats_selected[index1].priceInCents =
                item.PriceInCents;
              item1.isBogo = true;
            } else {
              // console.log("Is Bogo true two")
              item1.TicketTypeCode = item.TicketTypeCode;
              state.vista_seats_selected[index1].priceInCents = 0;
              item1.isBogo = true;
            }
          }




          if (item1.AreaCategoryCode == item.AreaCategoryCode &&
            item.PackageContent &&
            item.PackageContent.Tickets &&
            item.PackageContent.Tickets.length <= 1 &&
            item.PackageContent.Tickets[0]?.Quantity == 4 &&
            (item.Description.split(' ').includes('FAMILY') || item.DisplayName.split(' ').includes('FAMILY') || item?.display_ticket_type_group_name.includes('Family'))
          ) {

            console.log(item1, "ITEM1 >>>")
            console.log(item, "ITEM >>>")

            if (item1.priceInCents == 0) {
              // console.log(
              //   (item[index + 1].TicketTypeCode = type.TicketTypeCode),
              //   "couple"
              // );
              item1.priceInCents = 0;
              item1.TicketTypeCode = item.TicketTypeCode;
            } else {
              item1.TicketTypeCode = item.TicketTypeCode;
              item1.priceInCents = item.PriceInCents / 4;
            }

            item1.isFamilyBundle = true;
            item1.isBogo = false;
          }
          else if (
            item1.AreaCategoryCode == item.AreaCategoryCode &&
            item.PackageContent &&
            item.PackageContent.Tickets &&
            item.PackageContent.Tickets.length <= 1 &&
            item.PackageContent.Tickets[0]?.Quantity == 2 &&
            item.Description.split(' ').includes('PROMO')
          ) {
            console.log(item1, "ITEM1 >>>>>>>>>>>>>>>>>>>>")
            console.log(item, "ITEM >>>")

            if (item1.priceInCents == 0) {
              // console.log(
              //   (item[index + 1].TicketTypeCode = type.TicketTypeCode),
              //   "couple"
              // );
              item1.priceInCents = 0;
              item1.TicketTypeCode = item.TicketTypeCode;
            } else {
              item1.TicketTypeCode = item.TicketTypeCode;
              item1.priceInCents = item.PriceInCents;
            }

            item1.ispkgDoubleSeatOffer = true;
          }

          else if (
            item1.AreaCategoryCode == item.AreaCategoryCode &&
            item.PackageContent &&
            item.PackageContent.Tickets &&
            item.PackageContent.Tickets.length <= 1
          ) {

            //  console.log("UNUUHHJFHDHFHD")

            if (item1.coupleSeat == true) {
              if (item1.coupleSeat == true && item1.priceInCents == 0) {
                // console.log(
                //   (item[index + 1].TicketTypeCode = type.TicketTypeCode),
                //   "couple"
                // );
                item1.priceInCents = 0;
                item1.TicketTypeCode = item.TicketTypeCode;
              } else {
                item1.TicketTypeCode = item.TicketTypeCode;
                item1.priceInCents = item.PriceInCents;
              }
            } else {
              item1.TicketTypeCode = item.TicketTypeCode;
              item1.priceInCents = item.PriceInCents;
            }
            item1.isBogo = false;
            //alert();
            // state.selectedTicketPrice.priceInCents -= item1.priceInCents;
            // state.selectedTicketPrice.originalPriceInCents -=
            //   item1.priceInCents;
            // state.selectedTicketPrice.totalWithFnb -= item1.priceInCents;
            // state.selectedTicketPrice.originalTotalWithFnb -=
            //   item1.priceInCents;
            // state.selectedTicketPrice.taxInCents -= parseFloat(
            //   (
            //     item1.priceInCents -
            //     parseFloat(
            //       (item1.priceInCents / (1 + state.taxRate)).toFixed(2)
            //     )
            //   ).toFixed(2)
            // );
          }
          // else {
          //             console.log("not match");
          //             item1.TicketTypeCode = item1.TicketTypeCode;
          //             item1.priceInCents = item1.priceInCents;
          //             state.selectedTicketPrice.priceInCents += item1.priceInCents;
          //             state.selectedTicketPrice.originalPriceInCents +=
          //               item1.priceInCents;
          //             state.selectedTicketPrice.totalWithFnb += item1.priceInCents;
          //             state.selectedTicketPrice.originalTotalWithFnb +=
          //               item1.priceInCents;
          //           }
          // if (item1.isBogo == true && item1.priceInCents == 0) {
          //   bogofree += 1;
          // }
          // if (item1.isBogo == true && item1.priceInCents != 0) {
          //   bogoprice += 1;
          // }
        });
      });

      console.log(state.selectedTicketPrice.originalPriceInCents, "CADSFADSFDSFAFDF")
      state.selectedTicketPrice.priceInCents = 0;
      // state.selectedTicketPrice.originalPriceInCents = 0;
      state.selectedTicketPrice.totalWithFnb = 0;
      // state.selectedTicketPrice.originalTotalWithFnb = 0;
      state.selectedTicketPrice.taxInCents = 0;
      state.vista_seats_selected.map((item, index) => {

        console.log(item, "KLJKLKJKKL")

        if (item.isBogo == true) {
          let bogoNotMatch = state.vista_seats_selected.filter((z) => {
            return z.TicketTypeCode == item.TicketTypeCode;
          });

          if (
            bogoNotMatch.length > 0 &&
            bogoNotMatch.length != 2 &&
            bogoNotMatch.length != 4 &&
            bogoNotMatch.length != 6 &&
            bogoNotMatch.length != 8 &&
            bogoNotMatch.length != 10 &&
            bogoNotMatch.length != 12 &&
            bogoNotMatch.length != 14
          ) {
            bogofree += 1;
          }
        }

        if (item.isFamilyBundle == true) {
          let isFamilyBundleNotMatch = state.vista_seats_selected.filter((z) => {
            return z.TicketTypeCode == item.TicketTypeCode;
          });

          if (
            isFamilyBundleNotMatch.length > 0 &&
            isFamilyBundleNotMatch.length != 4 &&
            isFamilyBundleNotMatch.length != 8 &&
            isFamilyBundleNotMatch.length != 12
          ) {
            familyBundlefree += 1;
          }
        }



        // console.log(state, "item1.isFamilyBundle")

        // state.selectedTicketPrice.priceInCents += item.priceInCents;
        // state.selectedTicketPrice.originalPriceInCents = state.selectedTicketPrice.originalPriceInCents;
        // state.selectedTicketPrice.totalWithFnb += item.priceInCents;
        // state.selectedTicketPrice.originalTotalWithFnb = state.selectedTicketPrice.originalTotalWithFnb;
        // state.selectedTicketPrice.taxInCents += parseFloat(
        //   (
        //     item.priceInCents -
        //     parseFloat((item.priceInCents / (1 + state.taxRate)).toFixed(2))
        //   ).toFixed(2)
        // );

        console.log(state.selectedTicketPrice.taxInCents, "state.selectedTicketPrice.taxInCents >>>>>>>>>>")

        if (item.isFamilyBundle == true) {
          state.selectedTicketPrice.priceInCents += item.priceInCents * 2;
          state.selectedTicketPrice.originalPriceInCents = state.selectedTicketPrice.originalPriceInCents + (state.selectedTicketPrice.priceInCents - state.selectedTicketPrice.originalPriceInCents)
          state.selectedTicketPrice.totalWithFnb += item.priceInCents * 2;
          state.selectedTicketPrice.originalTotalWithFnb = state.selectedTicketPrice.originalTotalWithFnb;
          state.selectedTicketPrice.taxInCents += parseFloat(
            (
              item.priceInCents -
              parseFloat((item.priceInCents / (1 + state.taxRate)).toFixed(2))
            ).toFixed(2)
          );
        } else {
          state.selectedTicketPrice.priceInCents += item.priceInCents;
          state.selectedTicketPrice.originalPriceInCents = state.selectedTicketPrice.originalPriceInCents;
          state.selectedTicketPrice.totalWithFnb += item.priceInCents;
          state.selectedTicketPrice.originalTotalWithFnb = state.selectedTicketPrice.originalTotalWithFnb;
          state.selectedTicketPrice.taxInCents += parseFloat(
            (
              item.priceInCents -
              parseFloat((item.priceInCents / (1 + state.taxRate)).toFixed(2))
            ).toFixed(2)
          );
        }

        // console.log(state.selectedTicketPrice.priceInCents, "jjjjjjjjjjjj")
      });

      state.selectedTicketPrice.originalTaxInCents =
        state.selectedTicketPrice.taxInCents;

      console.log(state.selectedTicketPrice.taxInCents, "SGADSFDSFADSFDFADSFDSF")
      if (bogofree > 0) {
        state.bogoOfferSeatNotMatch = true;
      } else {
        state.bogoOfferSeatNotMatch = false;
      }

      if (familyBundlefree > 0) {
        state.isFamilyBundleNotMatch = true;
      } else {
        state.isFamilyBundleNotMatch = false;
      }

      console.log(
        bogoprice,
        "bbb jitu",
        bogofree,
        "oo",
        state.selectedTicketPrice
      );
      return {
        ...state,
        cinepolisOffers: userSelectedOffer,
      };
    case SET_RESERVATION_ID:
      return {
        ...state,
        reservation_id: action.payload,
      };
    case SET_SEAT_SELECT_ERROR:
      return {
        ...state,
        seat_select_error: action.payload,
      };
    case SET_TRANSACTION_ID:
      return {
        ...state,
        transaction_id: action.payload,
        transaction_error: null,
      };
    case SET_TRANSACTION_ERROR:
      return {
        ...state,
        transaction_id: null,
        transaction_error: action.payload,
      };
    case CLEAR_BOOKING_STATE:
      return {
        ...state,
        seat_layout_loading: false,
        seat_layout: [],
        original_seat_layout: [],
        isVoucherAvailable: false,
        cinepolisOffers: null,
        bogoOfferSeatNotMatch: false,
        seat_layout_error: null,
        vista_seats_selected: [],
        reservation_id: null,
        seat_select_error: null,
        transaction_error: null,
        selectedTicketPrice: {
          priceInCents: 0,
          taxInCents: 0,
          totalWithFnb: 0,
          originalPriceInCents: 0,
          originalTaxInCents: 0,
          originalTotalWithFnb: 0,
        },
        bankOffersList: [],
        selectedBankOfferTicket: null,
        selectedBankOfferCardObj: null,
        bankOfferUserCardDetails: {
          firstSixDigits: null,
          lastSixDigits: null,
        },
        offerType: "",
        remaining_seats: null,
      };
    case SET_TICKET_PRICE:
      return {
        ...state,
        ticketPrice: action.payload,
      };
    case SET_GUEST_ID:
      return {
        ...state,
        guest_id: action.payload,
      };
    case START_TIMER:
      let seconds = state.timer.seconds - 1;
      let minutes = state.timer.minutes;
      let showPopup = false;
      if (seconds == 0) {
        if (minutes == 0) {
          seconds = 0;
          minutes = 0;
          showPopup = true;
        }
        seconds = 59;
        minutes = minutes - 1;
      }
      var timer = Object.assign({}, state.timer);
      timer = {
        minutes: minutes,
        seconds: seconds,
      };
      return {
        ...state,
        timer: timer,
        showTimeoutPopup: showPopup,
      };
    case STOP_TIMER:
      var _timer = Object.assign({}, state.timer);
      _timer = {
        minutes: 8,
        seconds: 59,
      };
      return {
        ...state,
        timer: _timer,
      };
    case SET_BOOKING_FEE_DATA:
      const bookingFee = action.payload;
      const bookingFeeTax = round(
        bookingFee - bookingFee / (1 + state.taxRate),
        2
      );
      return {
        ...state,
        bookingFee,
        bookingFeeTax,
      };
    case SET_ADDITIONAL_DATA:
      return {
        ...state,
        additionalData: {
          ...state.additionalData,
          ...action.payload,
        },
      };
    case SET_SESSION_DETAILS:
      return {
        ...state,
        session_id: action.payload.session_id,
        cinema_id: action.payload.cinema_id,
      };
    case SET_OFFERTYPE:
      return {
        ...state,
        offerType: action.payload
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        seat_layout_error: null,
      };
    case CLEAR_SELECTED_SEATS:
      return {
        ...state,
        seats_selected: [],
        vista_seats_selected: [],
        voucherCodeData: null,
        voucherCodeError: null,
        session_id: null,
        cinema_id: null,
        bankOffersList: [],
        selectedBankOfferTicket: null,
        selectedBankOfferCardObj: null,
        bankOfferUserCardDetails: {
          firstSixDigits: null,
          lastSixDigits: null,
        },
        offerType: "",
      };
    case CLEAR_TIMEOUT_POPUP:
      return {
        ...state,
        showTimeoutPopup: false,
      };
    case SET_VOUCHER_CODE_SUCCESS:
      const voucher_data = action.payload;
      let isBogo = false;
      let isOnlyTwo = false;
      let isOnlyThree = false
      let isOnlyFour = false
      let isOnlyFive = false
      let isOnlySix = false

      console.log(voucher_data, "voucher_datavoucher_datavoucher_data")

      if (voucher_data.ticketTypes.length > 0) {
        voucher_data.ticketTypes.forEach((type) => {
          let ticketPriceObj = state.ticketPrice
            ? state.ticketPrice.Tickets && state.ticketPrice.Tickets.length > 0
              ? state.ticketPrice.Tickets.find(
                (t) => t.TicketTypeCode === type.ticketTypeCode
              )
              : null
            : null;

          console.log(ticketPriceObj, "ticketPriceObj >>>>>>>>>>>>>>>>>>>>>")
          if (
            ticketPriceObj &&
            ticketPriceObj.PackageContent &&
            ticketPriceObj.PackageContent.Tickets &&
            ticketPriceObj.PackageContent.Tickets.length === 2
          ) {
            voucher_data.Description = ticketPriceObj.Description;
            isBogo = true;
          } else if (
            ticketPriceObj &&
            ticketPriceObj.PackageContent &&
            ticketPriceObj.PackageContent.Tickets &&
            ticketPriceObj.PackageContent.Tickets.length > 0 &&
            ticketPriceObj.PackageContent.Tickets[0].Quantity == 2
          ) {
            voucher_data.Description = ticketPriceObj.Description;
            isBogo = true;
            isOnlyTwo = true;
          } else if (
            ticketPriceObj &&
            ticketPriceObj.PackageContent &&
            ticketPriceObj.PackageContent.Tickets &&
            ticketPriceObj.PackageContent.Tickets.length > 0 &&
            ticketPriceObj.PackageContent.Tickets[0].Quantity == 3
          ) {
            voucher_data.Description = ticketPriceObj.Description;
            // isBogo = true;
            isOnlyThree = true;
          } else if (
            ticketPriceObj &&
            ticketPriceObj.PackageContent &&
            ticketPriceObj.PackageContent.Tickets &&
            ticketPriceObj.PackageContent.Tickets.length > 0 &&
            ticketPriceObj.PackageContent.Tickets[0].Quantity == 4
          ) {
            voucher_data.Description = ticketPriceObj.Description;
            // isBogo = true;
            isOnlyFour = true;
          }
          else if (
            ticketPriceObj &&
            ticketPriceObj.PackageContent &&
            ticketPriceObj.PackageContent.Tickets &&
            ticketPriceObj.PackageContent.Tickets.length > 0 &&
            ticketPriceObj.PackageContent.Tickets[0].Quantity == 5
          ) {
            voucher_data.Description = ticketPriceObj.Description;
            // isBogo = true;
            isOnlyFive = true;
          } else if (
            ticketPriceObj &&
            ticketPriceObj.PackageContent &&
            ticketPriceObj.PackageContent.Tickets &&
            ticketPriceObj.PackageContent.Tickets.length > 0 &&
            ticketPriceObj.PackageContent.Tickets[0].Quantity == 6
          ) {


            voucher_data.Description = ticketPriceObj.Description;
            // isBogo = true;

            isOnlySix = true;
          }


          else {
            voucher_data.Description = ticketPriceObj.Description;
          }
        });
      }

      voucher_data.isBogo = isBogo;
      voucher_data.isOnlyTwo = isOnlyTwo;
      voucher_data.isOnlyThree = isOnlyThree;
      voucher_data.isOnlyFour = isOnlyFour;
      voucher_data.isOnlyFive = isOnlyFive;
      voucher_data.isOnlySix = isOnlySix;

      return {
        ...state,
        voucherCodeData: voucher_data,
        voucherCodeError: null,
        seats_selected: [],
        vista_seats_selected: [],
        seat_layout: cloneDeep(state.original_seat_layout),
        selectedTicketPrice: {
          priceInCents: 0,
          taxInCents: 0,
          totalWithFnb: 0,
          originalPriceInCents: 0,
          originalTaxInCents: 0,
          originalTotalWithFnb: 0,
        },
        offerType: VOUCHER,
      };
    case SET_VOUCHER_CODE_ERROR:
      return {
        ...state,
        voucherCodeData: null,
        voucherCodeError: action.payload,
      };
    case SET_BANK_OFFERS_LIST: {
      return {
        ...state,
        bankOffersList: action.payload,
      };
    }


    case SET_SELECTED_BANK_OFFER: {
      let selectedBankOfferTicket = action.payload.selectedBankOfferTicket;
      console.log(selectedBankOfferTicket, "bank offers slecteced")

      if (
        selectedBankOfferTicket &&
        selectedBankOfferTicket.PackageContent &&
        selectedBankOfferTicket.PackageContent.Tickets &&
        selectedBankOfferTicket.PackageContent.Tickets.length === 2
      ) {
        selectedBankOfferTicket.isBogo = true;
      } else {
        selectedBankOfferTicket.isBogo = false;
      }

      return {
        ...state,
        selectedBankOfferTicket: selectedBankOfferTicket,
        selectedBankOfferCardObj: action.payload.selectedBankOfferCardObj,
        bankOfferUserCardDetails: action.payload.bankOfferUserCardDetails,
        offerType: BANK_OFFER,
        seats_selected: [],
        vista_seats_selected: [],
        seat_layout: cloneDeep(state.original_seat_layout),
        selectedTicketPrice: {
          priceInCents: 0,
          taxInCents: 0,
          totalWithFnb: 0,
          originalPriceInCents: 0,
          originalTaxInCents: 0,
          originalTotalWithFnb: 0,
        },
      };
    }

    case REMOVE_SELECTED_BANK_OFFER: {

      // console.log("newState >>>>>>>>>>>>>>>>>>>>>>>>")

      state.bankOfferUserCardDetails.firstSixDigits = null
      state.bankOfferUserCardDetails.lastFourDigits = null
      state.selectedBankOfferCardObj = null
      state.selectedBankOfferTicket = null

    }

    case SET_REMAINING_SEATS_LIMIT: {
      return {
        ...state,
        remaining_seats: action.payload,
      };
    }
    default:
      return state;
  }
};

export default bookingReducer;
